// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import calendar from '@src/views/apps/calendar/store'
import users from '@src/views/apps/user/store'
import invoice from '@src/views/apps/invoice/store'
import finance from '@src/views/apps/finance/store'

const rootReducer = {
  auth,
  navbar,
  layout,
  calendar,
  users,
  invoice,
  finance
}

export default rootReducer
