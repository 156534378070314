// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import qs from 'qs'

// ** Axios Imports
import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API
})

const getHeaders = () => {
  return {
    'x-user-document': JSON.parse(localStorage.getItem('userData'))?.document || null
  }
}

export const getAllData = createAsyncThunk('appInvoice/getAllData', async () => {
  const headers = getHeaders()

  const response = await api.get('/calendar/all-events', { headers })
  return response.data
})

export const getData = createAsyncThunk('appInvoice/getData', async config => {
  const headers = getHeaders()

  const response = await api.get('/analytics/events/list', {
    params: {
      config
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
    headers
  })

  return {
    params: config,
    data: response.data.events,
    totalPages: response.data.total
  }
})

export const finishEvent = createAsyncThunk('calendar/finishEvent', async (obj, { dispatch, getState }) => {
  const headers = getHeaders()

  await api.post('/calendar/finish-event', { obj }, { headers })
  await dispatch(getData(getState().invoice.params))
  return obj
})

export const appInvoiceSlice = createSlice({
  name: 'appInvoice',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.allData = action.payload.allData
      })
  }
})

export default appInvoiceSlice.reducer
