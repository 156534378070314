// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import qs from 'qs'

// ** Axios Imports
import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API
})

const getHeaders = () => {
  return {
    'x-user-document': JSON.parse(localStorage.getItem('userData'))?.document || null
  }
}

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const headers = getHeaders()

  const response = await api.get('/members/all-members', { headers })
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async config => {
  const headers = getHeaders()

  const response = await api.get('/members/list-members', {
    params: {
      config
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
    headers
  })

  return {
    params: config,
    data: response.data.members,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const headers = getHeaders()

  const response = await api.get('/members/find-member', {
    params: {
      id
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
    headers
  })
  return response.data.member
})

export const addUser = createAsyncThunk('appUsers/addUser', async (member, { dispatch, getState }) => {
  const headers = getHeaders()

  await api.post('/members/add-member', { member }, { headers })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return member
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  const headers = getHeaders()

  await api.delete('/members/remove-member', {
    params: {
      id
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
    headers
  })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appUsersSlice.reducer
